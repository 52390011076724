import {Params} from '@angular/router';
import {OverlayCloseEventType} from '@modules/modal/interfaces/overlay-close-event';

import {AvailabilityFormI} from '@controls/availability-form';
import {ExpectedSalaryFormI} from '@controls/expected-salary-form';
import {CheckboxGroupItem} from '@controls/seazone-checkbox-group-deprecated';
import {RangeDto} from '@controls/seazone-range-slider';
import {buildURLParams} from '@helpers/build-url-params';
import {parseParamsToArray} from '@helpers/parse-params-to-array';

import {CompanyDto} from '../auth';
import {CrewSearchStatus} from '../crew';
import {CoverLetterDto} from '../crew-cv';
import {CrewExpectedSalaryCurrencyDto} from '../crew-search';
import {
  ActivityTypeDto,
  BoatTypeDto,
  BoatTypeSectionDto,
  CurrencyDto,
  RolePositionDto,
  RolePositionTypeDto,
} from '../directories';
import {booleanToConditionalStatus, ConditionalStatus} from '../general';
import {
  JobOfferCoupleDto,
  JobOfferInvitationStatusType,
  JobOfferInvitationType,
  JobOfferQuestionDto,
  JobOfferStatusType,
} from '../job-offers';
import {LocationLatLngDto} from '../location';
import {ContractReadyStatuses} from '../my-contracts';
import {PaginationDto} from '../response';
import {MatchingResultType, SearchChipsItemDto, SearchMatchingDto} from '../search';
import {UploadedFile} from '../uploaded-file';

export enum AppliedResivedType {
  Applied = 1,
  Resived = 2
}

export type JobSearchType = 'all' | 'favorite' | 'notRelevant' | 'applied' | 'received';

export type JobSearchApplyAnswerType = 'skip' | 'apply' | 'redirect';

export class JobOfferActionsResponseParams {
  constructor(
    public readonly type: JobSearchApplyAnswerType,
    public readonly jobOfferInvitationId?: number,
  ) {

  }
}

export class JobSearchFilterDirectories {
  constructor(
    public readonly positionTypes: RolePositionTypeDto[] = [],
    public readonly contractTypes: CheckboxGroupItem[] = [],
    public readonly availableStatuses: CheckboxGroupItem[] = [],
    public readonly salary: CrewExpectedSalaryCurrencyDto[] = [],
    public readonly yachtLength: RangeDto = {min: 1, max: 100},
    public readonly boatTypes: BoatTypeDto[],
    public readonly boatActivityTypes: ActivityTypeDto[] = [],
    public readonly allPositions: RolePositionDto[] = [],
    public readonly chipsContracts: SearchChipsItemDto[] = [],
    public readonly chipsPositionGroups: SearchChipsItemDto[] = [],
    public readonly chipsPositions: SearchChipsItemDto[] = [],
    public readonly chipsExperiences: SearchChipsItemDto[] = [],
    public readonly chipsGreeny: SearchChipsItemDto | null = null,
    public readonly chipsCouple: SearchChipsItemDto | null = null,
    public readonly cv: Omit<JobSearchFilterForm, 'matchForMe' | 'expectedSalary' | 'couple'> | null = null,
    public readonly boatTypeSections: BoatTypeSectionDto[],
    public readonly chipsBoatTypeSections: SearchChipsItemDto[],
  ) {
  }
}

export class JobSearchListDto {
  constructor(
    public readonly list: UserJobOfferItemDto[],
    public readonly pagination: PaginationDto,
  ) {
  }
}

export class ContractInvitationDto {
  constructor(
    public readonly boatContractId: number,
    public readonly boatContractInvStatus: number,
    public readonly boatContractInvitationId: number,
    public readonly boatContractStatus: ContractReadyStatuses,
  ) {
  }
}

export class UserJobOfferItemDto {
  constructor(
    public readonly jobOfferId: number,
    public readonly boat: JobSearchBoatItemDto,
    public readonly boatTypes: SearchBoatTypes[],
    public readonly salary: UserJobOfferSalaryDto,
    public readonly contract: JobSearchContractItemDto,
    public readonly additional: JobSearchAdditionalItemDto,
    public readonly experience: JobOfferExperienceDto,
    public readonly contractInvitation?: ContractInvitationDto,
    public readonly pushedDate?: string,
    public readonly dateCreate?: string,
    public readonly dateUpdate?: string,
    public readonly dateUpdateStatus?: string,
    public readonly status?: JobOfferStatusType,
    public readonly name?: string,
    public readonly jobOfferInvitationId?: number,
    public readonly location?: JobSearchBoatLocationItemDto,
    public readonly joiningPlaceLocation?: JobSearchLocationItemDto,
    public readonly invStatus?: JobOfferInvitationStatusType,
    public readonly canSendApplyAgain?: boolean,
    public readonly canRevise?: boolean,
    public readonly invitationType?: JobOfferInvitationType,
    public readonly questions?: JobOfferQuestionDto[],
    public readonly matching?: SearchMatchingDto,
    public readonly couple?: JobOfferCoupleDto | null,
  ) {
  }
}

export interface SearchBoatTypes {
  readonly id: BoatTypes;
  readonly name: string;
  readonly tooltip: string[];
}

export enum BoatTypes {
  Motor = 1,
  Sail,
  Catamaran
}

export class BoatTypesItem {
  constructor(
    public readonly id: BoatTypes,
    public readonly name: string,
    public readonly icon: string,
  ) {
  }
}

export class JobOfferExperienceDto {
  constructor(
    public readonly from: number,
    public readonly to: number,
  ) {
  }
}

export class JobSearchLocationItemDto {
  constructor(
    public readonly distance: number,
    public readonly originString: string,
    public readonly lat: number | null = null,
    public readonly lng: number | null = null,
  ) {
  }
}

export class UserJobOfferSalaryDto {
  constructor(
    public readonly value: number,
    public readonly valueMax: number,
    public readonly negotiable: boolean,
    public readonly period: string,
    public readonly currency: string,
  ) {
  }
}

export class JobSearchBoatItemDto {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly shortName: string,
    public readonly boatLength: number,
    public readonly flag: string,
    public readonly image: string,
    public readonly boatActivityType: string,
    public readonly company: CompanyDto | null,
    public readonly location?: JobSearchLocationItemDto,
  ) {
  }
}

export class JobSearchBoatLocationItemDto {
  constructor(
    public readonly name: string,
    public readonly distance: number,
  ) {
  }
}

export class JobSearchContractItemDto {
  constructor(
    public readonly position: string,
    public readonly kind: string[],
    public readonly dateStart: string,
    public readonly dateEnd: string,
    public readonly flexible: boolean,
  ) {
  }
}

export class JobSearchAdditionalItemDto {
  constructor(
    public readonly favorite: boolean,
    public readonly note: string,
  ) {
  }
}

export class UserJobOfferMapItemDto {
  constructor(
    public readonly id: number,
    public readonly image: string,
    public readonly location: LocationLatLngDto,
  ) {
  }
}

export class UserJobOfferMapMarkerItemDto {
  constructor(
    public readonly jobOfferId: number,
    public readonly boatLength: string,
    public readonly shortName: string,
    public readonly flag: string,
    public readonly image: string,
    public readonly distance: number,
    public readonly name: string,
    public readonly position: string,
    public readonly location: UserJobOfferLocationDto,
    public readonly salary: UserJobOfferSalaryDto,
  ) {
  }
}

export class UserJobOfferLocationDto {
  constructor(
    public readonly lat: number,
    public readonly lng: number,
    public readonly originString: string,
  ) {
  }
}

export class JobSearchCommonInfoDto {
  constructor(
    public readonly expectedSalary: CrewExpectedSalaryCurrencyDto[],
    public readonly yachtLength: RangeDto,
    public readonly user: JobSearchUserDto | null = null,
  ) {
  }
}

export class JobSearchUserDto {
  constructor(
    public readonly cvCompleted: number,
    public readonly jobStatus: CrewSearchStatus,
    public readonly coverLetter: CoverLetterDto,
    public readonly cvData: JobSearchUserCvDto,
  ) {
  }
}

export class JobSearchUserCvDto {
  constructor(
    public readonly boatActivityType: number | null,
    public readonly boatType: number[] | null,
    public readonly contractType: number[] | null,
    public readonly couple: boolean | null,
    public readonly greeny: boolean | null,
    public readonly position: number[] | null,
    public readonly positionGroup: number[] | null,
    public readonly startDate: JobSearUserCvAvailabilityDto | null,
    public readonly yachtExperience: number | null,
    public readonly yachtLength: RangeDto | null,
  ) {
  }
}

export class JobSearUserCvAvailabilityDto {
  constructor(
    public readonly date: string | null,
    public readonly status: number | null,
  ) {
  }
}

export class JobSearchUserSalaryDto {
  constructor(
    public readonly max: number | null,
    public readonly min: number | null,
    public readonly currency: CurrencyDto | null,
    public readonly negotiable: boolean | null,
  ) {
  }
}

export class JobSearchUserWorkPreferenceDto {
  constructor(
    public readonly positionGroups: number[],
    public readonly position: number[],
  ) {
  }
}

export class CrewJobOfferCountDto {
  constructor(
    public readonly favorite: number,
    public readonly notRelevant: number,
    public readonly applied: CrewAppliedJobOfferCountDto,
    public readonly received: CrewReceivedJobOfferCountDto,
    public readonly receivedActions: CrewReceivedJobOfferCountDto,
    public readonly appliedActions: CrewAppliedJobOfferCountDto,
  ) {
  }
}

export class CrewAppliedJobOfferCountDto {
  constructor(
    public readonly all: number,
    public readonly pending: number,
    public readonly inProcess: number,
    public readonly declined: number,
    public readonly jobOfferHiredClosed: number,
    // public readonly jobOfferHired: number,
    // public readonly jobOfferClosed: number,
  ) {
  }
}

export type CrewAppliedJobOfferCountType = keyof CrewAppliedJobOfferCountDto;

export class CrewReceivedJobOfferCountDto {
  constructor(
    public readonly all: number,
    public readonly newOffer: number,
    public readonly inProcess: number,
    public readonly declined: number,
  ) {
  }
}

export type CrewReceivedJobOfferCountType = keyof CrewReceivedJobOfferCountDto;

export type CrewJobOfferCountType = CrewAppliedJobOfferCountType | CrewReceivedJobOfferCountType;

export class JobSearchFilterForm {
  constructor(
    public readonly positionGroup: number[] | null = null,
    public readonly position: number[] | null = null,
    public readonly contractType: number[] | null = null,
    public readonly availability: AvailabilityFormI | null = null,
    public readonly expectedSalary: ExpectedSalaryFormI | null = null,
    public readonly yachtLength: RangeDto | null = null,
    public readonly boatType: number[] | null = null,
    public readonly boatActivityType: number | null = null,
    public readonly couple: boolean | null = null,
    public readonly experience: RangeDto | null = null,
    public readonly greeny: boolean | null = null,
    public readonly matchForMe: boolean | null = null,
    public readonly boatTypeSection: number[] | null = null,
  ) {
  }
}

export class JobSearchFilterParams {
  readonly positionGroup: number[] | null = null;
  readonly position: number[] | null = null;
  readonly contractType: number[] | null = null;
  readonly startType: number[] | null = null;
  readonly startDate: string | null = null;
  readonly expectedSalaryCurrency: number | null = null;
  readonly expectedSalaryMin: number | null = null;
  readonly expectedSalaryMax: number | null = null;
  readonly yachtLengthMin: number | null = null;
  readonly yachtLengthMax: number | null = null;
  readonly boatType: number[] | null = null;
  readonly boatActivityType: number | null = null;
  readonly yachtExperienceMin: number | null = null;
  readonly yachtExperienceMax: number | null = null;
  readonly greeny: ConditionalStatus | null = null;
  readonly couple: ConditionalStatus | null = null;
  readonly matchForMe: ConditionalStatus | null = null;

  constructor(
    form: JobSearchFilterForm,
  ) {
    const {positionGroup, position, contractType, experience, greeny, couple, matchForMe} = form;
    this.positionGroup = this.getArrayParam(positionGroup);
    this.position = this.getArrayParam(position);
    const availability = form.availability;
    if (availability) {
      this.startType = availability.availabilityStatus;
      const statusWithDate = this.startType && this.startType.some(st => st === 4);
      this.startDate = statusWithDate && availability.availabilityDate || null;
    }
    this.contractType = this.getArrayParam(contractType);
    const expectedSalary = form.expectedSalary;
    if (expectedSalary) {
      const {currency, salary} = expectedSalary;
      this.expectedSalaryCurrency = currency;
      this.expectedSalaryMin = salary && salary.min;
      this.expectedSalaryMax = salary && salary.max;
    }
    const yachtLength = form.yachtLength;
    this.yachtLengthMin = yachtLength && yachtLength.min;
    this.yachtLengthMax = yachtLength && yachtLength.max;
    this.boatActivityType = form.boatActivityType;
    this.boatType = form.boatType;
    this.yachtExperienceMin = experience && experience.min;
    this.yachtExperienceMax = experience && experience.max;
    this.greeny = greeny ? booleanToConditionalStatus(greeny) : null;
    this.couple = couple ? booleanToConditionalStatus(couple) : null;
    this.matchForMe = matchForMe ? booleanToConditionalStatus(matchForMe) : null;
    this.boatType = form.boatType;
  }

  getArrayParam(val: any[] | null): any[] | null {
    if (!val || !val.length) {
      return null;
    }
    return val;
  }
}

export class JobSearchParamsPayload {
  readonly positionGroup: number[] | null = null;
  readonly position: number[] | null = null;
  readonly contractType: number[] | null = null;
  readonly startType: number[] | null = null;
  readonly startDate: string | null = null;
  readonly expectedSalaryCurrency: number | null = null;
  readonly expectedSalaryMin: number | null = null;
  readonly expectedSalaryMax: number | null = null;
  readonly yachtLengthMin: number | null = null;
  readonly yachtLengthMax: number | null = null;
  readonly boatType: number[] | null = null;
  readonly boatActivityType: number | null = null;
  readonly page: number | null;
  readonly limit: number;
  readonly myLocationLat: number | null = null;
  readonly myLocationLng: number | null = null;
  readonly distance: number | null = null;
  readonly type: number | null = null;
  readonly filter: JobOfferStatusType | null = null;
  readonly sort: JobSearchSortType | null = null;
  readonly jobOfferId: number | null = null;
  readonly yachtExperienceMin: number | null;
  readonly yachtExperienceMax: number | null;
  readonly greeny: ConditionalStatus | null;
  readonly couple: ConditionalStatus | null;
  readonly regions: number[] | null = null;

  constructor(
    params: Params,
    maxDistance: number,
  ) {
    const {positionGroup, position, contractType, startType, regions} = params;
    this.positionGroup = parseParamsToArray(positionGroup, true);
    this.position = parseParamsToArray(position, true);
    this.contractType = parseParamsToArray(contractType, true);
    this.startType = parseParamsToArray(startType, true);
    this.startDate = params.startDate;
    this.expectedSalaryCurrency = params.expectedSalaryCurrency;
    this.expectedSalaryMin = params.expectedSalaryMin;
    this.expectedSalaryMax = params.expectedSalaryMax;
    this.yachtLengthMin = params.yachtLengthMin;
    this.yachtLengthMax = params.yachtLengthMax;
    this.boatType = parseParamsToArray(params.boatType, true);
    this.boatActivityType = params.boatActivityType;
    const {myLocationLat, myLocationLng, distance} = params;
    this.page = params.page;
    this.limit = params.limit;
    if (myLocationLat && myLocationLng) {
      this.myLocationLat = params.myLocationLat;
      this.myLocationLng = params.myLocationLng;
      this.distance = distance || maxDistance;
    }
    this.jobOfferId = params.jobOfferId;
    this.type = params.type;
    this.filter = params.filter;
    this.sort = params.sort;
    this.yachtExperienceMin = params.yachtExperienceMin;
    this.yachtExperienceMax = params.yachtExperienceMax;
    this.greeny = params.greeny;
    this.couple = params.couple;
    this.regions = parseParamsToArray(regions, true);
  }
}

export class JobSearchMapParamsPayload extends JobSearchParamsPayload {

  readonly topLeftLng: number;
  readonly topLeftLat: number;
  readonly bottomRightLat: number;
  readonly bottomRightLng: number;
  readonly zoom: number;

  constructor(params: Params, maxDistance: number) {
    super(params, maxDistance);
    this.topLeftLng = params.topLeftLng;
    this.topLeftLat = params.topLeftLat;
    this.bottomRightLat = params.bottomRightLat;
    this.bottomRightLng = params.bottomRightLng;
    this.zoom = params.zoom;
  }
}

export interface ChangeJobOfferFavoriteStatusI {
  isFavorite: boolean;
  jobOfferId: number;
}

export interface ChangeJobOfferDeclineStatusI {
  notRelevant: ConditionalStatus;
  jobOfferInvitationId: number;
}

export interface ApplyJobOfferAgainFormI {
  canSendApplyAgain: boolean;
  jobOfferInvitationId: number;
}

export interface SendJobOfferAgainFormI {
  canSendOfferAgain: boolean;
  jobOfferInvitationId?: number;
  jobOfferId?: number;
}

export type SendJobOfferAgainPayload = SendAgainByJobOfferIdPayload | SendAgainByJobOfferInvitationIdPayload;

export interface SendAgainByJobOfferIdPayload {
  jobOfferId: number;
  userId: number;
}

export interface SendAgainByJobOfferInvitationIdPayload {
  jobOfferInvitationId: number;
}

export class JobSearchOfferPayload {
  constructor(
    public readonly jobOfferId: number,
    public readonly note: string,
  ) {
  }
}

export enum JobSearchSortType {
  LastUpdate = 1,
  SalaryMinMax,
  SalaryMaxMin,
  BoatLengthMinMax,
  BoatLengthMaxMin,
}

export class JobCrewSearchMapInfoWindowPayload {
  readonly offerIds: number[];
  readonly myLocationLat: number | null;
  readonly myLocationLng: number | null;

  constructor(offerIds: number[], params: Params) {
    const {myLocationLat, myLocationLng} = params;
    this.myLocationLat = myLocationLat;
    this.myLocationLng = myLocationLng;
    this.offerIds = offerIds;
  }
}

export interface JobSearchApplyForm {
  readonly coverLetter: string;
  readonly coverLetterFiles: UploadedFile[];
  readonly removeCoverLetterFiles: number[];
  readonly questions: JobSearchApplyQuestionForm[] | null;
}

export interface JobSearchApplyQuestionForm {
  readonly text: string;
  readonly answer: ConditionalStatus;
}

export class JobSearchApplyModalData {
  constructor(
    public readonly coverLetter: CoverLetterDto | null,
    public readonly jobOfferId: number,
    public readonly position: string,
    public readonly questions: JobOfferQuestionDto[] | null,
    public readonly matching: MatchingResultType | null,
  ) {
  }
}

export type JobSearchCoverLetterEvent = OverlayCloseEventType | 'applyWithoutCover' | 'apply';

export class JobSearchApplyPayload {
  readonly jobOfferId: number;
  readonly coverLetter: string | null;
  readonly removeCoverLetterFiles: number[] | null;

  constructor(
    jobOfferId: number,
    form: JobSearchApplyForm,
    withCover = true,
  ) {
    this.jobOfferId = jobOfferId;
    this.mapUserQuestions(form?.questions || null);
    if (withCover) {
      this.coverLetter = form?.coverLetter || null;
      this.mapCoverLetterFiles(form?.coverLetterFiles || null);
      this.removeCoverLetterFiles = form?.removeCoverLetterFiles || null;
    }
  }

  private mapCoverLetterFiles(files: UploadedFile[] | null): void {
    files?.forEach((item, index) => {
      this[`coverLetterFiles[${index}][filename]`] = item.fileName;
      this[`coverLetterFiles[${index}][originalFilename]`] = item.originalFileName;
    });
  }

  private mapUserQuestions(questions: JobSearchApplyQuestionForm[] | null): void {
    const params = buildURLParams(questions, false, 'question');
    Object.keys(params).forEach(key => {
      this[key] = params[key];
    });
  }
}
